.content-pane {
    display: flex;
    width: 500px;
    flex-direction: column;
    align-items: center;
    margin: 25px 0;
}

.content-pane > * {
    margin: 5px 0;
}

.button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button-row > * {
    margin: 0 5px;
}

.name {
    font-size: 32px;
}

.portrait {
    width: 200px;
    border-radius: 100px;
}