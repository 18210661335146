.background {
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  justify-content: center;

  font-family: monospace;

  box-shadow: inset 0 6px 14px -6px rgba(0, 0, 0, 0.5);
}